import React, {useState, useEffect} from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  CodeSnippet,
  Tabs,
  Tab,
} from 'carbon-components-react';
import { InfoSection, InfoCard } from '../../components/Info';
import Globe32 from '@carbon/icons-react/lib/globe/32';
import PersonFavorite32 from '@carbon/icons-react/lib/person--favorite/32';
import Application32 from '@carbon/icons-react/lib/application/32';
import { Image32 } from '@carbon/icons-react';
import {useLocation} from "react-router-dom";

import response from './testResponse.json';

const props = {
  tabs: {
    selected: 0,
    role: 'navigation',
  },
  tab: {
    role: 'presentation',
    tabIndex: 0,
  },
};


const IdentityProofingResponsePage = () => {
  let [idpResponse, setIdpResponse] = useState(null);
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(null);
  const responseData = JSON.stringify(response, null, 4);
  const search = useLocation().search;
  const resultsId = new URLSearchParams(search).get('resultsId');
  const fetchUrl = "https://colton-prod.ice.ibmcloud.com/profile/v3.0/flows/identity_proofing/identity-proofing-test/results/" + resultsId;
  useEffect(() => {
    fetch(fetchUrl)
    .then((response) => {
        if(response.ok){
          // response.json()
          // setIdpResponse(response.json())
          console.log(response)
          response.json().then((data) =>{
            // console.log(data);
            setIdpResponse(data)
          });
        }
        else throw response;
      })
    // .then((data) => setIdpResponse(data))
    .catch(error => {
      console.error("Error fetching data: ", error);
      error.json().then((errorData) => {
        setError(errorData)
      })
      // setError(error);
    })
    .finally(() => setLoading(false))
  },[])
  if (loading) return "Loading...";
  if (error) return "Error: " + JSON.stringify(error, null, 4);
  
  return (
    <div className="bx--grid bx--grid--full-width landing-page">
      <div className="bx--row landing-page__banner">
        <div className="bx--col-lg-16">
          <Breadcrumb noTrailingSlash aria-label="Page navigation">
            <BreadcrumbItem>
              <a href="/">Home</a>
            </BreadcrumbItem>
          </Breadcrumb>
          <h1 className="landing-page__heading">
            Identity Proofing Response
          </h1>
        </div>
      </div>
      <div className="bx--row landing-page__r2">
        <div className="bx--col bx--no-gutter">
          <Tabs {...props.tabs} aria-label="Tab navigation">
            <Tab {...props.tab} label="Identity Proofing">
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                <div className="bx--row landing-page__tab-content">
                  <div className="bx--col-md-4 bx--col-lg-7">
                    <p>{resultsId}</p>
                    <pre>{JSON.stringify(idpResponse, null, 4)}</pre>
                    <p>If results are null, your session timed out or you need to enable the CORS browser extension and refresh the page</p>
                    {/* <pre>{responseData}</pre> */}
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      {/* <InfoSection heading="The Principles of Software Engineering" className="landing-page__r3">
        <InfoCard
          heading="Programming is Fun"
          body="Whether it is solving problems or making a video game, programming is a great way to make something new. It's a lot like playing with Legos!"
          icon={<PersonFavorite32 />}
        />
        <InfoCard
          heading="Programming is Artistic"
          body="Designing a website or app to look the way you want is a great way to be artistic. You can design characters in a video game or a website with funny pictures. There are lots of ways to be artistic!"
          icon={<Image32 />}
        />
        <InfoCard
          heading="Programming is EVERYWHERE!"
          body="From video games on phones or iPads, the cars on the street, or even a stores website, Programming is everywhere in our lives. There are endless places where programming can make the workd a better place!"
          icon={<Globe32 />}
        />
      </InfoSection> */}
    </div>
  );
};

export default IdentityProofingResponsePage;
